import * as React from "react";
// import { Redirect } from "@reach/router";
import { useEffect } from "react";

const TerminologyManagementPage = () => {
    useEffect(()=>{
      if (typeof window !== 'undefined') {
        window.location = 'https://blog.andovar.com/terminology-management';
      }
    },[])
  return <></>;
};
export default TerminologyManagementPage;
